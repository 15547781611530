import React from 'react';
import LoginButton from '../components/LoginButton';

const LandingPage = () => {
  return (
    <div>
      <h1>Welcome to the Landing Page</h1>
      <LoginButton />
    </div>
  );
};

export default LandingPage;
