import React, { useEffect } from 'react';

const LoginButton = () => {
  useEffect(() => {
    // Code to include and execute the external Telegram widget script
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js';
    script.async = true;
    script.setAttribute('data-telegram-login', 'xswcebot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-radius', '10');
    script.setAttribute('data-auth-url', 'http://127.0.0.1:8000/api/telegram/callback');
    script.setAttribute('data-request-access', 'write');
    document.body.appendChild(script);

    // Optional cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
    </div>
  );
};

export default LoginButton;
