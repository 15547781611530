import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import UserLandingPage from './pages/UserLandingPage';

const App = () => {
  // State to keep track of user authentication
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to handle successful login
  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          {/* Show the logout link if authenticated */}
          {isAuthenticated && (
            <li>
              <Link to="/user-landing-page">User Landing Page</Link>
            </li>
          )}
        </ul>
      </nav>

      <Routes>
        <Route
          path="/"
          element={<LandingPage isAuthenticated={isAuthenticated} onLoginSuccess={handleLoginSuccess} />}
        />
        {/* Protected route for authenticated users */}
        <Route
          path="/user-landing-page"
          element={<UserLandingPage onLogout={handleLogout} />}
        />
        {/* Redirect to the landing page for unauthenticated users */}
        {isAuthenticated ? (
          <Route path="*" element={<Navigate to="/user-landing-page" />} />
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
      </Routes>
    </Router>
  );
};

export default App;
