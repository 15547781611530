// UserLandingPage.jsx
import React from 'react';

const UserLandingPage = () => {
  return (
    <div>
      <h1>Welcome to the User Landing Page</h1>
      {/* Add content for authenticated users */}
    </div>
  );
};

export default UserLandingPage;
